var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper-privacy"
  }, [_c('div', {
    staticClass: "wrapper-logo"
  }, [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.navigateToPlatform();
      }
    }
  }, [_c('svgLoader', {
    staticClass: "logo",
    attrs: {
      "src": "/img/logo-full.svg",
      "color": "#ff5326"
    }
  })], 1)]), _c('h1', [_vm._v("TERMOS DE SERVIÇO")]), _c('privacy'), _c('button', {
    staticClass: "generic-button main-btn",
    on: {
      "click": function click($event) {
        return _vm.navigateToPlatform();
      }
    }
  }, [_vm._v(" Ir para Plataforma ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }