import Privacy from '@/components/privacy/privacy.vue'
import SvgLoader from '@/components/svg-loader/index.vue'

export default {
  name: 'ServiceTerms',
  components: {
    Privacy,
    SvgLoader
  },
  methods: {
    navigateToPlatform () {
      this.$router.push({ name: 'Private' })
    }
  }
}
